import React, {  useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import "./Settings.css";

export default function NewNote() {
  const history = useHistory();
  const [clientID, setclientID] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return clientID.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
  
    try {
      await updateTDsettings({ clientID });
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  
  function updateTDsettings(settings) {
    return API.post("notes", "/resetTDAaccess", {
      body: settings
    });
  }

  function goBack() {    
    history.push("/");
    return;
  }

  return (
    <div className="TDSettings">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="clientID">
          <FormControl
            value={clientID}
            componentClass="textarea"
            onChange={e => setclientID(e.target.value)}
          />
        </FormGroup>            
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          bsStyle="primary"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Update
        </LoaderButton>
        <Button
          block
          type="button"
          bsSize="large"
          bsStyle="primary"
          onClick={goBack}
        >
          Back
        </Button>
      </form>
    </div>
  );
}