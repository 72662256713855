  const dev = {
    STRIPE_KEY: "pk_test_51H7WO3BfDsF89vczmMHrUnrBQnQDxbMpvXAftq6cF01q4JwyLCVCUoA06eagng6YhsDT62zS3SDgoivyCyeePqKi00GMl6wRBb",
    s3: {
      REGION: "us-west-2",
      BUCKET: "ks-notes-app-2-api-dev-attachmentsbucket-zv7sp47xrgs8"
    },
    apiGateway: {
      REGION: "us-west-2",
      URL: "https://api.expressme.io/dev"
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_pwxa1yOix",
      APP_CLIENT_ID: "4ckbu9gbis8f6ebaqbkk4bc6lf",
      IDENTITY_POOL_ID: "us-west-2:9ac4d1e5-28b4-43d6-a375-47e5536de331"
    }
  };
  
  const prod = {
    STRIPE_KEY: "pk_test_51H7WO3BfDsF89vczmMHrUnrBQnQDxbMpvXAftq6cF01q4JwyLCVCUoA06eagng6YhsDT62zS3SDgoivyCyeePqKi00GMl6wRBb",
    s3: {
      REGION: "us-west-2",
      BUCKET: "ks-notes-app-2-api-prod-attachmentsbucket-xhteacd24d0c"
    },
    apiGateway: {
      REGION: "us-west-2",
      URL: "https://api.expressme.io/prod"
    },
    cognito: {
      REGION: "us-west-2",
      USER_POOL_ID: "us-west-2_FW2wfzo05",
      APP_CLIENT_ID: "647e3bjupji1euicpu59bu2vdl",
      IDENTITY_POOL_ID: "us-west-2:3f6be912-2aed-4a4f-8526-afc0bd9c6abf"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };