import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { Image } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "./StationDetails.css";

export default function Stations() {
    
    const { id } = useParams();
    const [message, setMessage] = useState(null);
    const [imgURL , setimgURL] = useState("");

    useEffect(() => {       
        let repeat;

        function loadStation() {
            return API.get("notes", `/stations/${id}`);
          }

        async function onLoad() {
 
            try {
                const station = await loadStation();
                console.log(station);      
                setMessage(station.Items[0].message);
                setimgURL(station.Items[0].attachURL);    
                repeat = setTimeout(onLoad, 100000);          
            }
            catch (e) {
                onError(e);
            }
             
        } 
        onLoad();      
        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }  
    }, [id]);

    return (
        <div>
          <h1>{message}</h1>
          <div>
              <br/>
          </div>
          { imgURL && ( <Image src={imgURL} className="photo" alt=" " /> )}
        </div>
      );
}
