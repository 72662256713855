import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import { FormGroup, FormControl, ControlLabel, Image } from "react-bootstrap";
import "./Stations.css";

export default function Stations() {
    
    const [optionItems, setOptionItems] = useState(null);
    const [message, setMessage] = useState(null);
    const [imgURL , setimgURL] = useState("");

    useEffect(() => {
        function loadStations() {
            return API.get("notes", `/stations`);
          }

        async function onLoad() {
            try {
              const station = await loadStations();               
              station.push({"stationId":"0","descr":"Select"});
              let optionItemsVal = station.map((station) =>
              <option key={station.stationId} value={station.stationId}>{station.descr}</option>
              ); 
              let optionItemsVal1 =optionItemsVal.reverse();
              setOptionItems(optionItemsVal1);              
            }
            catch (e) {
                onError(e);
            }
        } 
        onLoad();        
    }, []);

    function loadStation(stationId) {
      return API.get("notes", `/stations/${stationId}`);
    }

    async function handleSubmitClick(event) {
 
      //alert(event.target.value);
      const station = await loadStation(event.target.value);
      console.log(station);      
      setMessage(station.Items[0].message);
      setimgURL(station.Items[0].attachURL);
      //alert(station.Items[0].message);
        //event.preventDefault();
      }

    return (
        <div>
          <form className="Stations" onSubmit={handleSubmitClick}>
            <FormGroup bsSize="large" controlId="stationName">
              <ControlLabel>Choose a Station</ControlLabel>
              <FormControl
                componentClass="select" 
                type="submit"
                placeholder="4"  
                onChange={handleSubmitClick}                  
              >
                {optionItems}
              </FormControl>
            </FormGroup>              
          </form>
          <h1>{message}</h1>
          <div>
              <br/>
          </div>
          { imgURL && ( <Image src={imgURL} className="photo" alt=" " /> )}
        </div>
      );
}
