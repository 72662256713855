import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API, Storage  } from "aws-amplify";
import { onError } from "../libs/errorLib";
import config from "../config";
import { Elements, StripeProvider } from "react-stripe-elements";
import BillingForm from "../components/BillingForm";
import "./Publish.css";

export default function Publish() {

    const [stripe, setStripe] = useState(null);
    const [optionItems, setOptionItems] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        function loadStations() {
            return API.get("notes", `/stations`);
          }

        async function onLoad() {
            try {
              const station = await loadStations(); 
              station.push({"stationId":"0","descr":"select"});
              let optionItemsVal = station.map((station) =>
              <option key={station.stationId} value={station.stationId}>{station.descr}</option>
              ); 
              let optionItemsVal1 =optionItemsVal.reverse();
              setOptionItems(optionItemsVal1);
            }
            catch (e) {
                onError(e);
            }
        } 
        onLoad();
        setStripe(window.Stripe(config.STRIPE_KEY));
    }, []);


  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  function billUser(details) {
    return API.post("notes", "/billing", {
      body: details
    });
  }


  function publishStation(stationName,details) {
    return API.post("notes", `/stations/${stationName}/publish`, {
      body: details
    });
  }

  function loadNote() {
    return API.get("notes", `/notes/${id}`);
  }

  async function handleFormSubmit(storage, stationName,{ token, error }) {
    if (error) {
      onError(error);
      return;
    }
  
    setIsLoading(true);
     
  
    try {
      await billUser({
        storage,
        source: token.id
      });

      const note = await loadNote();
      const { content, attachment } = note;

      if (attachment) {
        note.attachmentURL = await Storage.vault.get(attachment,{ expires: storage*60 });        
      }
      
      
      await publishStation(stationName,{
        "noteId":`${id}`,
        "content":content,
        "attachURL":note.attachmentURL
      });
  
      alert("Your card has been charged successfully!");
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }
  
  return (
    <div className="Publish">
      <StripeProvider stripe={stripe}>
        <Elements>
          <BillingForm isLoading={isLoading} optionItems={optionItems} onSubmit={handleFormSubmit} />
        </Elements>
      </StripeProvider>
    </div>
  );
}